<template>
  <CRow>
    <TblHighRiskStudent/>
  </CRow>
</template>

<script>

import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx
import TblHighRiskStudent from "./components/TblHighRiskStudent";

export default {
  name: "Dashboard",
  components: {  Datepicker, vSelect, XLSX, Datepicker, TblHighRiskStudent },
  data() {
    return {
      userId: this.$cookies.get("user").user_id,
      showModal: false,
      selectedAttId: null,
      selectedData: null,
      citeriaInfo: null,
      selectedCiteria: null,
      responseContent: null,
      darkModal: false,
      exportingData: null,
      teacherInfo: null,
      selectedTeacher: null,
      nowMonth: null,
      nowYear: null,
      userData: this.$cookies.get("user"),
      selectedDate: null,
      thMonth: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
    };
  },
  mounted() {

  },
  methods: {
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
            this.selectedAttId
        )
        .then((response) => {
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    updateData() {
      //SEND DATA
      const form = document.getElementById("formUpdateAbs");
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.selectedCiteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/attendanceAPI.php?txtAction=delete&attId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.excelRows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    updateTableForTeacher() {
      let teacherId = this.selectedTeacher.code;

      //FETCH Semester Stats BY TEACHER
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=getStatByTeacher&selectedSemester=" +
            this.$route.params.semesterId +
            "&teacherId=" +
            teacherId
        )
        .then((response) => {
          this.rows = response.data.mainData;
          if (this.rows == undefined) {
            this.rows = [{}];
          }
        })
        .finally(() => {});
    },
    getMonthStat(dataSwitch) {
      let dateObj = new Date();
      if (dataSwitch) {
        let dateData = document.getElementById("txtDate").value;
        dateObj = new Date(dateData);
      }
      let cDate = dateObj.toISOString().substring(0, 10);
      this.getSelectedMonth(cDate);
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=getRecordInMonth&schoolId=" +
            this.userData.user_address +
            "&txtDate=" +
            cDate
        )
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.excelRows = response.data.excelData;
          console.log(this.excelRows);
        })
        .finally(() => {});
    },
    getSelectedMonth(dataDate) {
      let d = new Date();
      if (dataDate) {
        d = new Date(dataDate);
      }
      this.nowYear = d.getFullYear() + 543;
      //alert(d.toISOString());
      //alert(d.getMonth());
      this.nowMonth = this.thMonth[d.getMonth()];
    },
  },
};
</script>

<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
