<template>
  <CCol lg="12">
    <CCard v-if="rows">
      <CCardHeader class="bg-warning">รายชื่อนักเรียนความเสี่ยงสูง</CCardHeader>
      <CCardBody >
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'stud_id', type: 'asc' },
          }"
          :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
        >
          >
          <template slot="table-row" slot-scope="props">

            <span v-if="props.column.field == 'stud_id'">
              <router-link
                :to="
                  '/statByStudent/' +
                  props.row.stud_id
                "
              >
                {{ props.row.stud_id }}
              </router-link>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>


          </template>
        </vue-good-table>
        <CRow style="margin-top: 20px">
          <CCol
            ><div style="text-align: right">
              <button @click="onExport" class="btn btn-secondary">
                Export
              </button>
              <!-- เพิ่มปุ่ม Export -->
            </div></CCol
          >
        </CRow>
      </CCardBody>
    </CCard>
    <div v-else class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>

  </CCol>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "./SltSubject.vue";
import SltStudent from "./SltStudent.vue";
import ChkPeriod from "./ChkPeriod.vue";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltSubject,
    SltStudent,
    ChkPeriod,
  },
  data() {
    return {
      columns: [

        {
          label: "เลขประจำตัวนักเรียน",
          field: "stud_id",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "ชั้น",
          field: "level_abv_name",
        },
         {
          label: "ห้อง",
          field: "std_class",
          type: 'number'
        },


        {
          label: "คะแนน",
          field: "tc_citeria_score",
        },
      ],
      rows: null,
      rowsExcel: null,
      userData: this.$cookies.get("user"),
      improveInfo: null,
      levelInfo: null,
      routeInfo: null,
      selectedCiteria: null,
      selectedAppointment: null,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedAttId: null,
      selectedRow:null,
      responseContent: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
    
    };
  },
  mounted() {
    //console.log(this.userData);

    //FETCH HIGH RISK STUDENT
    let formData = new FormData();
    formData.append("txtAction", "getRiskStudent");
    formData.append("schoolId", this.userData.user_address);
    formData.append("levelStart", 1);
    formData.append("levelEnd", 18);

    this.axios
      .post(this.$hostUrl + "php_action/attendanceAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
        this.rowsExcel = response.data.mainExcel;

      });

  },
  methods: {
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
            this.selectedAttId
        )
        .then((response) => {
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    updateData() {
      //SEND DATA
      const form = document.getElementById("formUpdateAbs");
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.selectedCiteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/attendanceAPI.php?txtAction=delete&attId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
  },
};
</script>
